import React, { useEffect, useState } from "react";

import styled from "styled-components";
import HTMLReactParser from "html-react-parser";
import ReactAudioPlayer from "react-audio-player";

import { Arrow, Audio, Mute, Pause, Play } from "components/icons";

import { black } from "theme/colors";
import { toRem, breakpoints } from "utils/mixins";

//STYLES
const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 160px;
  position: relative;
  &.show {
    /* transform: translateY(0); */
  }
  .intro-image {
    // order: 2;
    position: relative;
    width: 100vw;
    height: 60vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .intro-textC {
    width: 100%;
  }

  .intro-text {
    padding: ${toRem(20)};
    width: 100%;
    background-color: white;
    position: relative;
    p {
      margin-top: 0;
      color: ${black.default};
      font-weight: normal;
      font-style: normal;
      a {
        text-decoration: underline;
      }
    }
    .intro-arrow {
      left: 0;
      bottom: 40px;
      display: none;
      svg {
        height: 22px;
      }
    }
  }

  @media ${breakpoints.laptop} {
    display: flex;
    padding-top: 0;
    flex-direction: row;
    overflow: hidden;
    .intro-image {
      flex: unset;
      width: 50vw;
      height: 100vh;
    }
    .intro-textC {
      width: 50vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
    .intro-text {
      margin-top: 100px;
      max-width: ${toRem(700)};
      padding: 0;
      padding-right: ${toRem(40)};
      margin-left: ${toRem(40)};

      .intro-arrow {
        display: block;
      }
    }
  }
  @media (min-height: 800px) {
    .intro-text {
      margin-top: 0;
    }
  }
  @media (min-width: 1920px) {
    .intro-text {
      max-width: 100%;
      width: auto;
      margin-left: 15vw;
      padding-right: ${toRem(40)};
    }
  }
`;
const Wrapper = styled.div`
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.dataColor};
  color: ${(props) => props.dataText};
  .ola-images {
    position: absolute;
    width: 100%;
    height: ${(props) => (props.height ? props.height - 100 + "px" : "0")};
    .image-container {
      height: 33%;
      width: 100%;
      opacity: 0;
      &:nth-child(3) {
        opacity: 1;
      }
      img {
        margin: 0 auto;
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }
    @media (min-width: 1023px) {
      .image-container {
        opacity: 1;
        object-position: unset;
      }
    }
  }

  &.ola-wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    text-align: center;
    .container {
      z-index: 2;
      position: relative;
      padding-bottom: 50vh;
      .h2 {
        text-align: center;
      }
      p {
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.2px;
      }
    }
  }

  .container {
    padding: 0 20px;
    margin: 80px auto;
    position: relative;
    h2,
    .h2 {
      margin: 0;
      margin-top: 40px;

      text-align: left;
      font-family: "Futura PT";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
      text-transform: uppercase;

      color: #398b59;

      color: ${(props) => props.dataText};
    }
    ul {
      margin-left: 30px;
      list-style: disc;
    }
    p,
    li {
      font-family: "Futura PT Book";
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;

      color: ${black.default};

      color: ${(props) => props.dataText};
    }
    a {
      text-decoration: underline;
    }
  }
  @media ${breakpoints.laptop} {
    .container {
      margin: 100px auto;
    }
    &.ola-wrapper {
      .container {
        padding-bottom: 70vh;
      }
    }
  }
  @media (min-width: 1921px) {
    .container {
      margin: 100px 15%;
      width: 100%;
    }
    &.ola-wrapper {
      .container {
        padding-bottom: 35vh;
      }
    }
  }
`;
const Controls = styled.div`
  position: fixed;
  right: ${toRem(20)};
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  button {
    position: relative;
    display: block;
    margin-bottom: ${toRem(8)};
    padding: ${toRem(4)};
    height: ${toRem(28)};
    width: ${toRem(28)};
  }
  svg {
    position: absolute;
    left: ${toRem(6)};
    top: ${toRem(6)};
    display: block;
    height: ${toRem(16)};
  }
  @media ${breakpoints.laptop} {
    right: ${toRem(64)};
  }
`;
//STYLES

const OlaHino = (props) => {
  //PROPS
  const {
    dimensions,
    setColor,
    data: {
      page: {
        olaHino: { color, lyrics, images, intro }
      }
    }
  } = props;
  //PROPS

  //STATES
  const [height, setHeight] = useState(false);
  const [audio, setAudio] = useState({ playing: true, muted: false });
  //STATES

  //EFFECTS
  useEffect(() => {
    setColor(false);

    setHeight(document.querySelector(".ola-wrapper").offsetHeight);

    return () => {
      setColor(true);
    };
  }, [dimensions]);

  useEffect(() => {
    document.getElementById("audio").muted = true;
    document.getElementById("audio").play();
    setTimeout(() => {
      document.getElementById("audio").volume = 1;

      document.getElementById("audio").muted = false;
    }, 100);
  }, []);
  //EFFECTS

  return (
    <>
      <Container>
        <div className="intro-textC">
          <div className="intro-text">
            {intro.text && HTMLReactParser(intro.text)}
            <div className="intro-arrow">
              <Arrow color={black.default} />
            </div>
          </div>
        </div>
        <div className="intro-image">
          <img
            src={intro.image.localFile.childImageSharp.fluid.src}
            srcSet={intro.image.localFile.childImageSharp.fluid.srcSet}
            alt={intro.image.altText}
          />
        </div>
      </Container>
      <Wrapper
        dataColor={color}
        dataText={"white"}
        className="ola-wrapper"
        height={height}
      >
        <Controls>
          <ReactAudioPlayer
            id="audio"
            src="/AGIFMASTERNEWGUITAR.mp3"
            onEnded={() => setAudio({ ...audio, playing: false })}
            autoPlay={audio.playing}
            muted={audio.muted}
          />
          <button
            onClick={() => {
              !audio.playing
                ? document.getElementById("audio").play()
                : document.getElementById("audio").pause();
              setAudio({ ...audio, playing: !audio.playing });
            }}
            aria-label="Variar entre play e pause"
          >
            {audio.playing ? <Pause /> : <Play />}
          </button>
          <button
            onClick={() => {
              audio.muted
                ? (document.getElementById("audio").volume = 0)
                : (document.getElementById("audio").volume = 1);
              setAudio({ ...audio, muted: !audio.muted });
            }}
            aria-label="Variar entre som e mutado"
          >
            {audio.muted ? <Mute /> : <Audio />}
          </button>
        </Controls>
        <div className="container">{lyrics && HTMLReactParser(lyrics)}</div>
        <div className="ola-images">
          {images.map((image, index) => {
            return (
              <div className="image-container">
                {dimensions.width > 1023 ? (
                  <img
                    alt={image.image.altText}
                    src={image.image.localFile.childImageSharp.fluid.src}
                    srcSet={image.image.localFile.childImageSharp.fluid.srcSet}
                  />
                ) : (
                  <img
                    alt={image.imageMobile?.altText}
                    src={image.imageMobile?.localFile.childImageSharp.fluid.src}
                    srcSet={
                      image.imageMobile?.localFile.childImageSharp.fluid.srcSet
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
      </Wrapper>
    </>
  );
};

export const query = graphql`
  query OlaHino($id: String) {
    page: wpPage(id: { eq: $id }) {
      olaHino {
        intro {
          text
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  src
                  srcSet
                }
              }
            }
          }
        }
        color
        lyrics
        images {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  src
                  srcSet
                }
              }
            }
          }
          imageMobile {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OlaHino;
